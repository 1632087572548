import "./Advantages.css";
import adv from "../../image/adv.png";

function Advantages(props) {
  return (
    <section className="advantages">
      <h2 className="section__title">
        <span className="section__span">ПРЕИМУЩЕСТВА</span> КОЛЛАГЕНА
      </h2>
      <div className="advantages__container">
        <img className="advantages__img" src={adv} alt="adv" />
        <ul className="advantages__list">
          <li>
            <span className="list__span">
              Максимальное усвоение - в 2,5 раза выше чем у обычного коллагена
            </span>
          </li>
          <li>
            <span className="list__span">
              Большая упаковка - 180 капсул, хватит на один курс в 2 месяца
            </span>
          </li>
          <li>
            <span className="list__span">Капсулы по 500 мг</span>
          </li>
          <li>
            <span className="list__span">
              Без консервантов, без красителей, без сахара, без гмо
            </span>
          </li>
          <li>
            <span className="list__span">
              рыбный гидролизированный коллаген I и III типа
            </span>
          </li>
          <li>
            <span className="list__span">
              Удобная форма приема в виде капсул
            </span>
          </li>
          <li>
            <span className="list__span">Создан по Швейцарской технологии</span>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Advantages;
