import React from "react";
import "./Gallery.css";
import img1 from "../../image/gallery/1.png";
import img2 from "../../image/gallery/2.png";
import img3 from "../../image/gallery/3.png";
import img4 from "../../image/gallery/4.png";
import img5 from "../../image/gallery/5.png";
import img6 from "../../image/gallery/6.png";
import img7 from "../../image/gallery/7.png";
import img8 from "../../image/gallery/8.png";
import img9 from "../../image/gallery/9.png";

function Gallery(props) {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];

  function slidePlus() {
    props.n >= images.length - 1 ? props.setN(0) : props.setN(props.n + 1);
  }
  function slideMinus() {
    props.n <= 0 ? props.setN(images.length - 1) : props.setN(props.n - 1);
  }
  return (
    <section className="gallery">
      <h2 className="section__title">
        <span className="section__span">ДО</span>/ПОСЛЕ
      </h2>
      <div className="gallery__box">
        <button
          type="button"
          onClick={() => slideMinus()}
          className="gallery__button gallery__button_left"
        ></button>
        <img
          className="gallery__img"
          onClick={() => props.setPopupOpen(true)}
          src={images[props.n]}
          alt="gallery"
        />
        <button
          type="button"
          onClick={() => slidePlus()}
          className="gallery__button gallery__button_right"
        ></button>
      </div>
    </section>
  );
}

export default Gallery;
