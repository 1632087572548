import "./Smi.css";
import news from "../../image/10news.png";
import zdorove from "../../image/zdorove.png";
import globalinfo from "../../image/globalinfo.png";
import probeautyspace from "../../image/probeautyspace.png";

function Smi(props) {
  return (
    <section className="smi">
      <h2 className="section__title">
        <span className="section__span">СМИ О</span> НАС
      </h2>
      <div className="smi__container">
        <a
          className="smi__box"
          href="https://10news-russia.ru/news/gendernii-collagen-biofarmeco/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="smi__img" src={news} alt="smi" />
          <p className="smi__title">
            Почему гендерный коллаген для суставов эффективнее обычного
          </p>
        </a>
        <a
          className="smi__box"
          href="https://zdorove-russia.ru/news/kakoi-kollagen-luchshe/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="smi__img" src={zdorove} alt="smi" />
          <p className="smi__title">Какой коллаген лучше?</p>
        </a>
        <a
          className="smi__box"
          href="https://globalinfo.info/gender-collagen/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="smi__img" src={globalinfo} alt="smi" />
          <p className="smi__title">
            Гендерный коллаген – это коллаген последнего поколения
          </p>
        </a>
        <a
          className="smi__box"
          href="https://probeautyspace.com/instrumenty-i-materialy/rejtingi/v-rejtinge-kollagenov-pobedil-gendernyj-kollagen/"
          target="_blank"
          rel="noreferrer"
        >
          <img className="smi__img" src={probeautyspace} alt="smi" />
          <p className="smi__title">
            В рейтинге коллагенов, победил Гендерный коллаген
          </p>
        </a>
      </div>
    </section>
  );
}

export default Smi;
