import "./Principle.css";
import apigenin from "../../image/Apigenin.png";
import egcg from "../../image/Epigallocatechin_gallate.png";
import genistein from "../../image/Genistein.png";
import hesperidin from "../../image/Hesperidin.png";
import React from "react";

function Principle(props) {
  const [isButton, setButton] = React.useState("female");
  return (
    <section className="principle">
      <h2 className="section__title">
        <span className="section__span">ПРИНЦИП</span> РАБОТЫ
      </h2>
      <div className="principle__button-box">
        <button
          type="button"
          onClick={() => setButton("female")}
          className={
            isButton === "female"
              ? "principle__button principle__button_female"
              : "principle__button"
          }
        >
          ДЛЯ ЖЕНЩИН
        </button>
        <button
          type="button"
          onClick={() => setButton("male")}
          className={
            isButton === "male"
              ? "principle__button principle__button_male"
              : "principle__button"
          }
        >
          ДЛЯ МУЖЧИН
        </button>
      </div>
      {isButton === "male" ? (
        <div className="principle__container">
          <p className="principle__text">
            Основной мужской гормон – тестостерон. Помимо прочих функций он
            влияет на синтез эритроцитов. Чем активнее стимулируются
            клетки-транспортеры кислорода, тем лучше обеспечиваются кислородом
            все клетки и ткани, а значит, растет регенерация.
          </p>
          <p className="principle__text">
            Адъювантами в мужском коллагеновом комплексе выступают натуральный
            флавонид апигенин и гесперидин.
          </p>
          <div className="principle__box">
            <img className="principle__img" src={apigenin} alt="principle" />
            <div className="principle__text-box">
              <h3 className="principle__title">Апигенин</h3>
              <p className="principle__text">
                стимулирует секрецию тестостерона; действует как ингибитор
                ферментов, отвечающих за инактивацию тестостерона; оказывает
                анаболический эффект.
              </p>
            </div>
          </div>
          <div className="principle__box">
            <img className="principle__img" src={hesperidin} alt="principle" />
            <div className="principle__text-box">
              <h3 className="principle__title">Гесперидин</h3>
              <p className="principle__text">
                мощный антиоксидант, защищает клетки от атаки свободных
                радикалов и от повреждений ультрафиолетом; сохраняет
                концентрацию тестостерона, подавляя фермент ароматазы
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="principle__container">
          <p className="principle__text">
            Важный нюанс – наши гендерные препараты не меняют естественный
            гормональный фон и не влияют на концентрацию гормонов. Они лишь
            стимулируют процесс усвоения полезных веществ. Это возможно
            благодаря стимуляции эстрогенами функций щитовидной железы, которая
            регулирует процессы всасывания и метаболизма. При этом организм
            самостоятельно определяет, какой по типу коллаген лучше, и где в
            данный момент большая потребность в нем.
          </p>
          <p className="principle__text">
            Коллагеновый комплекс для женщин представляет собой уникальную
            швейцарскую формулу. В его составе природный фитоэстроген –
            генистеин, природный кахетин (EGCG).
          </p>
          <div className="principle__box">
            <img className="principle__img" src={genistein} alt="principle" />
            <div className="principle__text-box">
              <h3 className="principle__title">ГЕНИСТЕИН</h3>
              <p className="principle__text">
                обладает мощным анаболическим эффектом; стимулирует женские
                половые гормоны; стимулирует выработку фермента, необходимого
                для синтеза коллагена.
              </p>
            </div>
          </div>
          <div className="principle__box">
            <img className="principle__img" src={egcg} alt="principle" />
            <div className="principle__text-box">
              <h3 className="principle__title">EGCG</h3>
              <p className="principle__text">
                обеспечивает защиту естественного коллагена от повреждений и
                преждевременного старения; стимулирует появление новых клеток
                коллагена.
              </p>
            </div>
          </div>
          <p className="principle__text">
            Таким образом, наш коллаген для лица, кожи, волос и женского
            здоровья состоит исключительно из натуральных компонентов и
            представляет собой природный легко усваиваемый эффективный
            концентрат.
          </p>
        </div>
      )}
    </section>
  );
}

export default Principle;
