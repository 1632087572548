import React from "react";
import "./Feedback.css";
import { FeedbackContext } from "../../context/FeedbackContext";
import nofoto from "../../image/nofoto.svg";

function Feedback(props) {
  const feed = React.useContext(FeedbackContext);
  const [n, setN] = React.useState(1);
  function slidePlus() {
    n >= feed.length ? setN(1) : setN(n + 1);
  }
  function slideMinus() {
    n <= 1 ? setN(feed.length) : setN(n - 1);
  }
  return (
    <section className="feedback">
      <h2 className="section__title">
        <span className="section__span">ОТЗЫВЫ</span>
      </h2>
      <div className="feedback__container">
        <button
          type="button"
          onClick={() => slideMinus()}
          className="feedback__button feedback__button_left"
        ></button>
        {feed.map((f) =>
          Number(f.key) === Number(n) ? (
            <div className="feedback__text-box">
              <div key={`${f.key}feed`} className="feedback__box">
                <img className="feedback__avatar" src={nofoto} alt="nofoto" />
                <p className="feedback__name">
                  {f.name} <span className="feedback__span">{f.surname}</span>
                </p>
              </div>
              <p className="feedback__text">{f.text}</p>
              <div className="feedback__img-box">
                {f.images.map((i) => (
                  <img
                    key={`${f.images.indexOf(i)}feedimg`}
                    className="feedback__img"
                    src={i}
                    onClick={() => {
                      props.setPopupOpenFeed(true);
                      props.setFeedImg(f.imagesbig[f.images.indexOf(i)]);
                    }}
                    alt="foto"
                  />
                ))}
              </div>
            </div>
          ) : (
            <></>
          )
        )}

        <button
          type="button"
          onClick={() => slidePlus()}
          className="feedback__button feedback__button_right"
        ></button>
      </div>
    </section>
  );
}

export default Feedback;
