import "./Header.css";
import logo from "../../image/logo.png";
import bfe from "../../image/bfe.png";

function Header() {
  return (
    <header className="header">
      <img className="header__logo" src={logo} alt="logo" />
      <a className="header__link" href="#buy">
        Где купить?
      </a>
      <div className="header__box">
        <img className="header__bfe" src={bfe} alt="bfe" />
        <p className="header__text">Разработано по швейцарской технологии</p>
      </div>
    </header>
  );
}

export default Header;
