import "./Buy.css";
import ozon from "../../image/ozon.png";
import wb from "../../image/wb.png";

function Buy(props) {
  return (
    <section className="buy" id="buy">
      <h2 className="section__title">
        <span className="section__span">ГДЕ</span> КУПИТЬ?
      </h2>
      <div className="buy__container">
        <a
          className="buy__box"
          href="https://www.ozon.ru/seller/biofarmeco-393151/apteka-6000/?miniapp=seller_393151"
          target="_blank"
          rel="noreferrer"
        >
          <img className="buy__img" src={ozon} alt="smi" />
        </a>
        <a
          className="buy__box"
          href="https://www.wildberries.ru/brands/311071017-biofarmeco"
          target="_blank"
          rel="noreferrer"
        >
          <img className="buy__img" src={wb} alt="smi" />
        </a>
      </div>
    </section>
  );
}

export default Buy;
