import "./Main.css";
import main from "../../image/main.png";
import hair from "../../image/1.png";
import nail from "../../image/2.png";
import skin from "../../image/3.png";
import heart from "../../image/4.png";
import leg from "../../image/5.png";
import strong from "../../image/6.png";

function Main() {
  return (
    <section className="main">
      <div className="main__container">
        <div className="main__box">
          <h2 className="main__subtitle">ИННОВАЦИЯ</h2>
          <h1 className="main__title">
            <span className="main__span">ГЕНДЕРНЫЙ</span> морской коллаген
          </h1>
          <div className="main__text-box">
            <p className="main__text-number">в 2,5 раза</p>
            <p className="main__text">
              <span className="main__text-span">более</span> эффективное
              усвоение
            </p>
          </div>
        </div>
        <img className="main__img" src={main} alt="main" />
      </div>
      <div className="main__box-icon">
        <div className="main__icon-box">
          <img className="main__icon" src={hair} alt="hair" />
          <p className="main__icon-text">Восстановление волос</p>
        </div>
        <div className="main__icon-box">
          <img className="main__icon" src={nail} alt="nail" />
          <p className="main__icon-text">Укрепление ногтей</p>
        </div>
        <div className="main__icon-box">
          <img className="main__icon" src={skin} alt="Гладкая кожа" />
          <p className="main__icon-text">Гладкая кожа</p>
        </div>
        <div className="main__icon-box">
          <img
            className="main__icon"
            src={heart}
            alt="Улучшает работу сердца и сосудов"
          />
          <p className="main__icon-text">Улучшает работу сердца и сосудов</p>
        </div>
        <div className="main__icon-box">
          <img className="main__icon" src={leg} alt="Здоровые суставы" />
          <p className="main__icon-text">Здоровые суставы</p>
        </div>
        <div className="main__icon-box">
          <img
            className="main__icon"
            src={strong}
            alt="Эластичными мышцы и связки"
          />
          <p className="main__icon-text">Эластичными мышцы и связки</p>
        </div>
      </div>
    </section>
  );
}

export default Main;
