import "./Footer.css";
import logo from "../../image/logo.png";
import bfe from "../../image/bfe.png";

function Footer() {
  return (
    <footer className="footer">
      <img className="footer__logo" src={logo} alt="logo" />
      <p className="footer__rule">Все права защищены 2024</p>
      <div className="footer__box">
        <img className="footer__bfe" src={bfe} alt="bfe" />
        <p className="footer__text">Разработано по швейцарской технологии</p>
      </div>
    </footer>
  );
}

export default Footer;
