import "./About.css";
import about from "../../image/about.png";
import about2 from "../../image/about2.png";

function About(props) {
  return (
    <section className="about">
      <h2 className="section__title">
        <span className="section__span">О</span> КОЛЛАГЕНЕ
      </h2>
      <div className="about__container">
        <img
          className="about__img"
          src={props.dimensions.width <= 722 ? about2 : about}
          alt="about"
        />
        <div className="about__box">
          <p className="about__text">
            Гендерный морской коллаген является морским коллагеном,
            представляющим собой наиболее универсальные типы белка - I и III.{" "}
          </p>
          <p className="about__text">
            Пептидные молекулы коллагена I типа необходимы организму для
            поддержания здоровья кожи, ногтей, костей, сухожилий, эпителия ЖКТ,
            зубов, связок и органов.{" "}
          </p>
          <p className="about__text">
            Коллаген III типа является одним из белков, участвующих в
            поддержании здоровья сосудистой стенки, а также кожи и мышц.{" "}
          </p>
          <p className="about__text">
            Благодаря дополнительным адъювантом, входящим в состав коллагенового
            комплекса, достигается высокая усваиваемость пептидных молекул
            коллагена (в 2.5 раза выше чем у обычного коллагена), а также
            создаются оптимальные условия для защиты уже существующего в
            организме естественного коллагена.
          </p>
          <p className="about__text">
            Гендерный коллаген для женщин и мужчин показал высокую эффективность
            благодаря сочетанию аминокислот и фитогормонов. Формула с природным
            фитоэстрогеном и комплексом EGCG обеспечивает синтез коллагена и
            предотвращает разрушение уже имеющихся волокон от старения и
            разрушения. Каждая капсула содержит натуральный концентрат,
            выделенный путем экстракции, который легко расщепляется и через
            стенки желудка всасывается в кровь. Это самый простой, удобный и
            надежный способ восполнения дефицита коллагена.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
