import About from "../About/About";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Main from "../Main/Main";
import React from "react";
import "./App.css";
import Advantages from "../Advantages/Advantages";
import Principle from "../Principle/Principle";
import Gallery from "../Gallery/Gallery";
import Popup from "../Popup/Popup";
import img1 from "../../image/gallery/1b.png";
import img2 from "../../image/gallery/2b.png";
import img3 from "../../image/gallery/3b.png";
import img4 from "../../image/gallery/4b.png";
import img5 from "../../image/gallery/5b.png";
import img6 from "../../image/gallery/6b.png";
import img7 from "../../image/gallery/7b.png";
import img8 from "../../image/gallery/8b.png";
import img9 from "../../image/gallery/9b.png";
import Feedback from "../Feedback/Feedback";
import { FeedbackContext, feed } from "../../context/FeedbackContext";
import Smi from "../Smi/Smi";
import Buy from "../Buy/Buy";

function App() {
  const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9];
  const [n, setN] = React.useState(0);
  const [isBig, setBig] = React.useState(false);
  const [feedImg, setFeedImg] = React.useState("");
  const [isPopupOpen, setPopupOpen] = React.useState(false);
  const [isPopupOpenFeed, setPopupOpenFeed] = React.useState(false);
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
    });
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  function onClose() {
    setPopupOpen(false);
    setPopupOpenFeed(false);
  }
  return (
    <div className="App">
      <Header />
      <Main />
      <About dimensions={dimensions} />
      <Advantages />
      <Principle />
      <Gallery n={n} setN={setN} setPopupOpen={setPopupOpen} />
      <FeedbackContext.Provider value={feed}>
        <Feedback setPopupOpenFeed={setPopupOpenFeed} setFeedImg={setFeedImg} />
      </FeedbackContext.Provider>
      <Smi />
      <Buy />
      <Footer />
      <Popup onClose={onClose} isOpen={isPopupOpen} name="image">
        <img
          className={isBig ? "popup__img popup__img_big" : "popup__img"}
          onClick={() => setBig(!isBig)}
          src={images[n]}
          alt="Фото"
          type="image/jpeg"
        />
      </Popup>
      <Popup onClose={onClose} isOpen={isPopupOpenFeed} name="image">
        <img
          className={isBig ? "popup__img popup__img_big" : "popup__img"}
          onClick={() => setBig(!isBig)}
          src={feedImg}
          alt="Фото"
          type="image/jpeg"
        />
      </Popup>
    </div>
  );
}

export default App;
