import React from "react";
import img1 from "../image/feedback/1.jpg";
import img2 from "../image/feedback/2.jpg";
import img3 from "../image/feedback/3.jpg";
import img4 from "../image/feedback/4.jpg";
import img5 from "../image/feedback/5.jpg";
import img6 from "../image/feedback/6.jpg";
import img7 from "../image/feedback/7.jpg";
import img8 from "../image/feedback/8.jpg";
import img9 from "../image/feedback/9.jpg";
import img10 from "../image/feedback/10.jpg";
import img11 from "../image/feedback/11.jpg";
import img12 from "../image/feedback/12.jpg";
import img13 from "../image/feedback/13.jpg";
import img14 from "../image/feedback/14.png";
import img15 from "../image/feedback/15.png";
import img16 from "../image/feedback/16.png";
import img17 from "../image/feedback/17.png";
import img1b from "../image/feedback/1b.jpg";
import img2b from "../image/feedback/2b.jpg";
import img3b from "../image/feedback/3b.jpg";
import img4b from "../image/feedback/4b.jpg";
import img5b from "../image/feedback/5b.jpg";
import img6b from "../image/feedback/6b.jpg";
import img7b from "../image/feedback/7b.jpg";
import img8b from "../image/feedback/8b.jpg";
import img9b from "../image/feedback/9b.jpg";
import img10b from "../image/feedback/10b.jpg";
import img11b from "../image/feedback/11b.jpg";
import img12b from "../image/feedback/12b.jpg";
import img13b from "../image/feedback/13b.jpg";
import img14b from "../image/feedback/14b.png";
import img15b from "../image/feedback/15b.png";
import img16b from "../image/feedback/16b.png";
import img17b from "../image/feedback/17b.png";

export const FeedbackContext = React.createContext();

export const feed = [
  {
    key: "1",
    name: "Татьяна",
    surname: "Волкова",
    text:
      'Коллаген принимаю курсами. Заметила, что с началом приёма коллагена мои волосы и качество кожи улучшаются. Они выглядят более здоровыми. Мне такой "эффект на лицо" очень нравится, спасибо производителю',
    images: [img9, img10],
    imagesbig: [img9b, img10b],
  },
  {
    key: "2",
    name: "Алиса",
    surname: "Коваленко",
    text:
      "Пью постоянно, получила комплимент от мастера маникюра, что ногти стали крепчее и здоровее. Уже несколько раз подряд обходимся без укрепления. Отличный препарат!",
    images: [img8],
    imagesbig: [img8b],
  },
  {
    key: "3",
    name: "Михаил",
    surname: "Зубанов",
    text:
      "Очень хороший коллаген. Беру не в первый раз. Пьем вместе с женой. Нам подходит и очень нравится. Однозначно рекомендую. Спасибо производителю.",
    images: [img1, img2],
    imagesbig: [img1b, img2b],
  },
  {
    key: "4",
    name: "Злата",
    surname: "Курганова",
    text:
      "После курса заметила, что ногти у меня стали выглядеть лучше, перестали слоиться. Увидела и влияние на кожу, оно позитивное. Лицо как будто немного разгладилось и стало более упругим. Курс повторю!)",
    images: [img11],
    imagesbig: [img11b],
  },
  {
    key: "5",
    name: "Виктория",
    surname: "Комарова",
    text:
      "Долго искала соответствующий по составу и дозировке коллаген, так как мне необходимо было пропить курс по рекомендации косметолога. И я его нашла, удобные капсулы, состав то что надо. Удобный приём, баночка на 2х-месячный курс.",
    images: [img12, img13],
    imagesbig: [img12b, img13b],
  },
  {
    key: "6",
    name: "Елена",
    surname: "Филлимонова",
    text:
      "Koжа сохла невозможно, дерматолог посоветовал дополнитeльно пить коллаген. Он и правда помог мне. Заметила, что кoжа стала более упpyгой, а так же перестала шелушиться. Спасибо",
    images: [img14, img15],
    imagesbig: [img14b, img15b],
  },
  {
    key: "7",
    name: "Роман",
    surname: "Загребухин",
    text:
      "Результат есть, удобные способ приема. Пропиваю курс раз в квартал. Полностью доволен качеством. На суставы больше не жалуюсь. Рекомендую всем!",
    images: [img3, img4, img5],
    imagesbig: [img3b, img4b, img5b],
  },
  {
    key: "8",
    name: "Татьяна",
    surname: "Карпова",
    text:
      "Всегда беру этот коллаген. Только с ним увидела эффект от приема. Кожа стала более свежей и увлажненной, перестали беспокоить суставы. Пью данный бад раз в пол года для профилактики чувствую себя моложе )))",
    images: [img16],
    imagesbig: [img16b],
  },
  {
    key: "9",
    name: "Анна",
    surname: "Коцюба",
    text:
      "Нужен был рабочий коллаген, так как начали побаливать и хрустеть колени, посоветовали взять этой фирмы. Взяла в капсулах, для меня этот способ приёма удобнее, и вот пропив чуть больше двух недель я в приятном шоке, проблема почти прошла. Баночка рассчитана на 2 месяца, но я решила пропить длительным курсом, заказала ещё.",
    images: [img17],
    imagesbig: [img17b],
  },
  {
    key: "10",
    name: "Иван",
    surname: "Турчин",
    text:
      "Коллаген этой фирмы беру уже 2-й раз. До этого пробовал разный, но этот понравился больше всех. Кожа стала лучше и самое главное- связки стали прочнее и не болят ( рпди чего и покупал)!",
    images: [img6, img7],
    imagesbig: [img6b, img7b],
  },
];
